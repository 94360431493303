import { styled } from '@linaria/react';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';

export const Container = styled.div`
  display: flex;
  flex-flow: column;

  body[data-mobile='true'] & {
    align-items: center;
    height: 100%;
  }
`;

export const TitleText = styled(RMText)`
  align-self: flex-start;
`;

export const Form = styled.div`
  width: 100%;
  max-width: 380px;

  @media only screen and (max-width: 768px) {
    max-width: unset;
  }
`;

export const PurchaseButtonMobile = styled(RMButton)`
  position: absolute;
  bottom: var(--spacing-md);
`;
