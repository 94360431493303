import { RMText } from '@/components/RMText/RMText';

import { LoginLink } from './ProjectCheckoutLoginLink.styles';

export interface ProjectCheckoutLoginLinkProps {
  onClick: () => void;
}

export function ProjectCheckoutLoginLink({ onClick }: ProjectCheckoutLoginLinkProps) {
  return (
    <RMText type="sans" size="xs" color="on-surface-primary">
      Already have an account with Remento? Log in <LoginLink onClick={onClick}>here</LoginLink>.
    </RMText>
  );
}
